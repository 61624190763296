.change-address-modal__card {
	@transition-duration: 500ms;

	cursor: pointer;
	-webkit-transition: border @transition-duration;
	transition: border @transition-duration;

	&__active {
		border: 1px solid @primary-color !important;
	}

	&:hover {
		border: 1px solid @primary-color;
		-webkit-transition: border @transition-duration;
		transition: border @transition-duration;
	}
}

@primary-color: #db0124;@menu-collapsed-width: 40px;