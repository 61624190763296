.promotion-list-card {
	&__cover-image {
		overflow: hidden;
		height: 11em;
	}

	cursor: pointer;
	box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.1) !important;
}

@primary-color: #db0124;@menu-collapsed-width: 40px;