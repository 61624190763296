.product-promotion {
	&__name {
		&__title {
			display: inline-block;
		}
		&__badge {
			display: inline-block;
		}
	}
}

@primary-color: #db0124;@menu-collapsed-width: 40px;