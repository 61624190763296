.restaurant-menu-page-container-button-col {
	margin-top: 50px;
	margin-bottom: 100px;
}

.restaurant-menu-page-container-button {
	background-color: #db0322;
	color: white;
	font-weight: 500;
	font-size: 1.4em;
	height: 50px;
	width: 200px;
}

.restaurant-menu-page-container {
	background-color: black !important;
	min-height: 100vh !important;
}

@primary-color: #db0124;@menu-collapsed-width: 40px;