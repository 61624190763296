
.step-cart-restaurant-name {
    color: #db0322 !important;
    font-family: 'Montserrat', sans-serif;

    font-size: 4em;

    @media screen and (max-width: 576px) {
        font-size: 2.5em;
    }
}

.step-cart-top-row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.step-cart-summary {
    font-size: 1.5em;

    .step-cart-summary-price {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.step-cart-summary-row {
    margin-top: 20px;
    margin-bottom: 20px;
}
@primary-color: #db0124;@menu-collapsed-width: 40px;