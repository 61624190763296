.promotion-details {
	&__image {
		overflow: hidden;
		max-height: 200px;
	}

	&__discount-value {
		background-color: @primary-color;
		color: white !important;
		padding: 0.25em;
		text-align: center;
	}
}

@primary-color: #db0124;@menu-collapsed-width: 40px;