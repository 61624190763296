.confirmIconSuccess {
	color: @primary-color!important;
	font-size: 120px;
}

.confirmIconError {
	color: #ff4d4f !important; //antd red-5
	font-size: 120px;
}

.confirmEmail {
	padding-top: 80px;
}

@primary-color: #db0124;@menu-collapsed-width: 40px;