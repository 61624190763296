.step-delivery-delivery-type-radio-button {
	display: block !important;
}

.step-delivery-table-number-radio-button {
	border: 0.1px solid rgba(0, 0, 0, 0.05);
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);

	border-radius: 5px;
	color: white;
	margin: 2px;
	width: 40px;
	height: 40px;
	position: relative;
	padding: 2px !important;
}

.step-delivery-restaurant-name {
    color: #db0322 !important;
	font-family: 'Montserrat', sans-serif;
    font-size: 4em;

    @media screen and (max-width: 576px) {
        font-size: 2.5em;
    }
}

.step-delivery-top-row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.step-delivery-address-card {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
}

.step-delivery-delivery-type-radio-button {
	font-size: 16px!important;
	margin-bottom: 15px !important;
}
@primary-color: #db0124;@menu-collapsed-width: 40px;