@import '../../../../App.less';

.detailed-menu {
	&__price {
		color: red;
	}

	&__description {
		color: rgba(0, 0, 0, 0.4) !important;
		text-align: center !important;
	}

	&__quantity {
		margin-top: 20px;
	}

	&__add-to-cart-button {
		width: 100%;
		border-radius: 3px !important;
		background-color: #db0322 !important;
	}
}

.selected-dish-image {
	border-radius: 3px !important;
	max-width: 100%;
	margin-bottom: 20px;
}
@primary-color: #db0124;@menu-collapsed-width: 40px;