@import '~antd/lib/style/themes/default.less';

.menu {
	.ant-card-head {
		background-size: cover;
		padding: 1.5em;

		.ant-card-head-title {
			color: white;
			font-size: 1.5em;
		}
	}

	&__restaurant-title {
		font-family: "Marck Script",cursive;
		font-size: 1em;
		line-break:strict !important;
		display: block;
	}

	&__list-item {
		cursor: pointer;
	}

	&__price {
		font-weight: 700;
		color: @primary-color;
	}

	&__spicy {
		&1 {
			color: @orange-6 !important;
		}

		&2 {
			color: @volcano-6 !important;
		}

		&3 {
			color: @red-6 !important;
		}
	}

	&__long-to-prepare {
		color: @orange-9 !important;
	}

	&__vegan {
		color: @green-6 !important;
	}

	&__attribute {
		margin-right: 4px;
	}

	&__item-row {
		width: 100%;
	}

	&__item-image {
		height: 100%;
	}

	&__item-text-row {
		padding-left: 20px;
	}

	&__item-text-col {
		padding-right: 20px;
	}

	&__item-text-title {
		font-weight: bold;
		margin-bottom: 20px;

		color: white;
	}

	&__item-text-description {
		// color: rgba(0,0,0,0.4);

		color: white;

	}

	&__item-additional-options-icon {
		padding-left: 10px;
	}






}

@primary-color: #db0124;@menu-collapsed-width: 40px;