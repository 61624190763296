.forgot-password-container-image-block {
    background-image: url('./assets/profile-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    height: 300px;
    margin-bottom: 50px;

    @media screen and (max-width: 576px) {
        height: 200px;
	}
}

.forgot-password-container-image-block-text {
	color: white;
	font-size: 5em;
	font-family: 'Montserrat', sans-serif;
    
	@media screen and (max-width: 576px) {
        font-size: 2.5em;
	}
}
@primary-color: #db0124;@menu-collapsed-width: 40px;