@import '../../App.less';

.app-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -100px;
	margin-top: -100px;
	height: 200px;
	width: 200px;

	@media screen and (max-width: 992px) {
		position: sticky !important;
		top: calc(30vh - @header-height-mobile) !important ;
		height: 100px !important;
		width: 100px !important;
		margin-left: -50px;
		// margin-top: -50px !important;
	}
}

.login-container-form-block {
	@media screen and (max-width: 992px) {
		height: calc(70vh - @header-height-mobile) !important;
	}
	@media screen and (min-width: 992px) {
		height: calc(100vh - @header-height-desktop);
	}
}

.login-container-image-block {
	background-image: url('./assets/login.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@media screen and (max-width: 992px) {
		height: calc(30vh - @header-height-mobile) !important;
	}
	@media screen and (min-width: 992px) {
		height: calc(100vh - @header-height-desktop);
	}
}

@primary-color: #db0124;@menu-collapsed-width: 40px;